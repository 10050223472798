<template>
  <b-card-code no-body>
    <b-button
      @click="addAgent()"
      type="button"
      variant="primary"
      class="ml-auto m-1"
      size="sm"
    >
      Add New
    </b-button>
    <b-tabs>
      <b-tab title="External">
        <b-table
          v-if="this.rows"
          responsive
          id="agnTable"
          :fields="fields"
          :items="externalAgents"
          :per-page="perPage"
          :current-page="currentPage"
          hover
          style="min-height: 200px"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(agent_name)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                variant="primary"
                size="sm"
                :text="data.item.agent_name.charAt(0)"
              />
              <span class="d-flex" style="margin-left: 5px">{{
                data.item.agent_name
              }}</span>
            </div>
          </template>
          <template #cell(agentGroup)="data">
            <b-badge
              v-if="data.item.agent_group == 'internal'"
              variant="primary"
              class="text-capitalize"
              >{{ data.item.agent_group }}</b-badge
            >
            <b-badge v-else variant="info" class="text-capitalize">{{
              data.item.agent_group
            }}</b-badge>
          </template>
          <template #cell(agent_end_point)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                variant="primary"
                size="sm"
                :text="data.item.agent_name.charAt(0)"
              />
              <span
                class="d-flex truncated-text"
                style="margin-left: 5px"
                :title="data.item.agent_end_point"
              >
                {{ truncateWords(data.item.agent_end_point, 6) }}
              </span>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              size="sm"
              class="ml-1"
              variant="outline-primary"
              text="Actions"
            >
              <b-dropdown-item @click="editAgent(data.item.agent_id)"
                >Edit</b-dropdown-item
              >
              <b-dropdown-divider />
              <b-dropdown-item
                @click="gotoDelete(data.item.agent_id, data.item.agent_name)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div v-else class="m-1">
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-2 border-secondary rounded"
          >
            <feather-icon icon="BoxIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No External Scanners</h3>
            <p>
              <span class="font-weight-bold text-success" @click="addAgent()"
                >Click here</span
              >
              to add scanner
            </p>
          </div>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          :align="pagination_pos"
          aria-controls="agnTable"
        />
      </b-tab>
      <b-tab title="Internal">
        <b-table
          v-if="this.internalrows"
          responsive
          id="internalagnTable"
          :fields="internalfields"
          :items="internalAgents"
          :per-page="internalperPage"
          :current-page="internalcurrentPage"
          hover
          style="min-height: 200px"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + internalperPage * (internalcurrentPage - 1) }}
          </template>
          <template #cell(agent_name)="data">
            <div class="d-flex align-items-center">
              <span class="d-flex" style="margin-left: 5px">{{
                data.item.agent_name
              }}</span>
            </div>
          </template>
          <template #cell(agentGroup)="data">
            <b-badge
              v-if="data.item.agent_group == 'internal'"
              variant="primary"
              class="text-capitalize"
              >{{ data.item.agent_group }}</b-badge
            >
            <b-badge v-else variant="info" class="text-capitalize">{{
              data.item.agent_group
            }}</b-badge>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              size="sm"
              class="ml-1"
              variant="outline-primary"
              text="Actions"
            >
              <b-dropdown-item @click="editAgent(data.item.agent_id)"
                >Edit</b-dropdown-item
              >
              <b-dropdown-divider />
              <b-dropdown-item
                @click="gotoDelete(data.item.agent_id, data.item.agent_name)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div v-else class="m-1">
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-2 border-secondary rounded"
          >
            <feather-icon icon="BoxIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Internal Scanners</h3>
            <p>
              <span class="font-weight-bold text-success" @click="addAgent()"
                >Click here</span
              >
              to add scanner
            </p>
          </div>
        </div>
        <b-pagination
          v-model="internalcurrentPage"
          :total-rows="internalrows"
          :per-page="internalperPage"
          :align="pagination_pos"
          aria-controls="internalagnTable"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">{{ agent_name }}</span> ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteAgent()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  BButton,
  BCardText,
  BAvatar,
  BTab,
  BTabs,
  BTooltip,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BCardText,
    BAvatar,
    BTab,
    BTabs,
    BTooltip,
  },
  data() {
    return {
      // Call orgaization API and return in this format
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      internalperPage: 10,
      internalcurrentPage: 1,
      internalrows: 0,
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "agent_name", label: "Name" },
        { key: "agent_description", label: "Description" },
        { key: "agent_end_point", label: "End Point" },
        { key: "agent_meta", label: "Meta" },
        { key: "actions", label: "Actions" },
      ],
      internalfields: [
        { key: "index", label: "#" },
        { key: "agent_name", label: "Name" },
        { key: "agent_description", label: "Description" },
        { key: "agent_end_point", label: "End Point" },
        { key: "agent_meta", label: "Meta" },
        { key: "network_location_name", label: "Network Location" },
        { key: "actions", label: "Actions" },
      ],
      agent_name: "",
      agent_id: "",
      openDeleteModal: false,
      internalAgents: [],
      externalAgents: [],
    };
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    this.load();
  },
  methods: {
    truncateWords(text, numWords) {
      const words = text.split(" ");
      if (words.length > numWords) {
        return words.slice(0, numWords).join(" ") + "...";
      }
      return text;
    },
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/agent",
      };
      this.$http(options).then((res) => {
        const internalAgents = res.data.filter(
          (agent) => agent.agent_group === "internal"
        );
        const externalAgents = res.data.filter(
          (agent) => agent.agent_group === "external"
        );

        this.internalAgents = internalAgents;
        this.externalAgents = externalAgents;
        this.items = res.data;
        this.rows = this.externalAgents.length;
        this.internalrows = this.internalAgents.length;
      });
    },

    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    addAgent() {
      this.$router.push({ name: "Add Scanner" });
    },
    editAgent(id) {
      this.$router.push({ name: "Edit Scanner", params: { id: id } });
    },
    gotoDelete(agent_id, agent_name) {
      this.openDeleteModal = true;
      this.agent_id = agent_id;
      this.agent_name = agent_name;
    },
    deleteAgent() {
      // this.agent_name = agent_name;
      // if (confirm("Do you really want to delete "+ this.agent_name +"?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/agent/" + this.agent_id + "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}
.truncated-text {
  display: inline-block;
  max-width: 150px; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
